import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DateRangePicker } from '@brightsolutionsgmbh/biologis-react-components';

class GenerateReportFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      patientId: '',
      startDate: null,
      endDate: null
    };
  }

  render() {
    const { patientId, startDate, endDate } = this.state;
    const { handleSubmit } = this.props;

    return (
      <div className="filter">
        <Form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit({ patientId, startDate, endDate });
          }}
          inline
        >
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label for="patientId" className="mr-sm-2">
              <FormattedMessage id="patientList.patientId" />
            </Label>
            <Input
              type="text"
              name="patientId"
              value={patientId}
              onChange={e =>
                this.setState({
                  [e.target.name]: e.target.value
                })
              }
            />
          </FormGroup>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label className="mr-sm-2">
              <FormattedMessage id="patientList.requestDate" />
            </Label>
            <div>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={date => this.setState({ startDate: date })}
                onEndDateChange={date => this.setState({ endDate: date })}
              />
            </div>
          </FormGroup>
          <Button type="submit" color="primary">
            <FormattedMessage id="patientList.applyButton" />
          </Button>
        </Form>
      </div>
    );
  }
}

GenerateReportFilter.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export default GenerateReportFilter;
