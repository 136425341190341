export default {
  en: {
    loginForm: {
      username: 'Username',
      password: 'Password',
      loginButton: 'Log in',
      showPassword: 'Show password'
    },
    login: {
      welcome: 'Welcome',
      error: 'An error occured! Please try again.'
    },
    navbar: {
      upload: 'Upload',
      patientList: 'Generate',
      orderList: 'Finalize',
      content: 'Content',
      dashboard: 'Dashboard',
      variants: 'Variants',
      haplotypes: 'Haplotypes',
      phenotypes: 'Phenotypes'
    },
    paginator: {
      first: 'First',
      previous: 'Previous',
      next: 'Next',
      last: 'Last'
    },
    uploadForm: {
      label: `Drop files or browse`,
      dropFileHere: 'Drop here your files',
      uploadButton: 'Upload',
      error: {
        default: 'There was an error uploading the file.',
        mimeType: "The file's mimetype ({mimeType}) is not supported"
      }
    },
    upload: {
      successMessage: 'File successfully uploaded!',
      errorMessage: 'An error occured during file upload.'
    },
    patientList: {
      header: 'Generate GIMS.pharma reports',
      noResults: 'No patients found!',
      patientId: 'Patient ID',
      requestDate: 'Request date',
      reportLanguage: 'Report language',
      generateReport: 'Generate report',
      optionSelectForAll: 'select for all',
      optionSelect: 'select',
      optionGerman: 'german',
      optionEnglish: 'english',
      proceedButton: 'Proceed',
      applyButton: 'Apply',
      successModalMessage: `<p>Reports for following patients are generated: { ids }</p><p>Please change to ‚Finalize‘ for personalization and report download.</p>`,
      successModalButton: 'OK'
    },
    orderList: {
      header: 'Finalize GIMS.pharma report',
      noResults: 'No orders found!',
      patientId: 'Patient ID',
      requestDate: 'Request date',
      personalization: 'Personalization',
      generationStatus: 'Generation status',
      personalizationStatus: 'Personalization status',
      download: 'Download',
      generationStatusReady: 'Report generated',
      generationStatusPending: 'Report generation in progress',
      generationStatusReportError: 'Error',
      generationStatusWaitingForInput: 'Waiting for input',
      personalizationStatusReportDelivered: 'Report delivered',
      personalizationStatusPending: 'Report in progress',
      personalizationStatusError: 'Error',
      inputQuestionsButton: 'Input',
      inputContentVariableButton: 'Input',
      optionAny: 'Any',
      applyButton: 'Apply',
      questionModal: {
        saveButton: 'Save',
        sendButton: 'Send'
      },
      langCode: {
        de: 'German',
        en: 'English'
      },
      report: 'Report',
      safetyCard: 'Medication SafetyCard',
      coverLetter: 'Cover Letter'
    },
    footer: {
      support: 'Support',
      manual: 'Manual'
    }
  }
};
