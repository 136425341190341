import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { DateRangePicker } from '@brightsolutionsgmbh/biologis-react-components';
import Select from 'react-select';

class FinalizeReportFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      patientId: '',
      startDate: null,
      endDate: null,
      processingStatus: ''
    };
  }

  handleChange = ev => {
    this.setState({
      [ev.target.name]: ev.target.value
    });
  };

  render() {
    const { patientId, startDate, endDate, processingStatus } = this.state;
    const { handleSubmit, intl } = this.props;
    const generationStatusOptions = [
      {
        value: 'report_delivered',
        label: intl.formatMessage({
          id: 'orderList.generationStatusReady'
        })
      },
      {
        value: 'pending',
        label: intl.formatMessage({
          id: 'orderList.generationStatusPending'
        })
      },
      {
        value: 'error',
        label: intl.formatMessage({
          id: 'orderList.generationStatusReportError'
        })
      }
    ];

    return (
      <div className="filter">
        <Form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit({
              patientId,
              startDate,
              endDate,
              processingStatus
            });
          }}
          inline
        >
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label for="patientId" className="mr-sm-2">
              <FormattedMessage id="orderList.patientId" />
            </Label>
            <Input
              type="text"
              name="patientId"
              value={patientId}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label className="mr-sm-2">
              <FormattedMessage id="orderList.requestDate" />
            </Label>
            <div>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={date => this.setState({ startDate: date })}
                onEndDateChange={date => this.setState({ endDate: date })}
              />
            </div>
          </FormGroup>
          <div className="w-100 mr-2">
            <Label for="processingStatus">
              <FormattedMessage id="orderList.generationStatus" />
            </Label>
            <Select
              className="select"
              placeholder={intl.formatMessage({
                id: 'orderList.optionAny'
              })}
              options={[
                {
                  value: '',
                  label: intl.formatMessage({
                    id: 'orderList.optionAny'
                  })
                },
                ...generationStatusOptions
              ]}
              onChange={e => this.setState({ processingStatus: e.value })}
            />
          </div>
          <Button type="submit" color="primary">
            <FormattedMessage id="orderList.applyButton" />
          </Button>
        </Form>
      </div>
    );
  }
}

FinalizeReportFilter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(FinalizeReportFilter);
